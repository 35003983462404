import Block from "@clearpoint/old-theme/Block";
import FormCheck from "@clearpoint/old-theme/Form/Check/Form.Check";
import FormGroup from "@clearpoint/old-theme/Form/Form.Group";
import useFormValue from "@clearpoint/old-theme/Form/hooks/useFormValue";
import FormSelectEditor from "@clearpoint/old-theme/Form/Select/Select.Editor";
import FormSelectScorecardAdmin from "@clearpoint/old-theme/Form/Select/Select.ScorecardAdmin";
import Tooltip from "@clearpoint/old-theme/Tooltip";

import { useCheckFeature , useUserType } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";

import { useOldSession } from "@clearpoint/old-session/index";

let OptionsTab = () => {
	let requireApprovals = useFormValue("requireApprovals");
	let scorecardId = useFormValue("scorecardId");
	let {
		session: { scorecardId: currentScorecardId },
	} = useOldSession();
	let translate = useTranslate();
	let checkFeature = useCheckFeature();
	let approvalFlag = checkFeature("approvals");
	let userType = useUserType();
	return (
		<>
			<FormGroup title={translate("global.scorecardVisibility")}>
				<FormCheck name="grantAdminAccess" text={translate("edit.element.grantAccess")} />
				{scorecardId === currentScorecardId ? (
					<Tooltip placement="left" text={translate("tooltips.theCurrentScorecardCannotBeLockedOrArchived")}>
						<FormCheck name="locked" text={translate("options.locked")} disabled={true} />
						<FormCheck name="archived" text={translate("goals.archived")} disabled={true} />
					</Tooltip>
				) : (
					<>
						<FormCheck name="locked" text={translate("options.locked")} disabled={false} />
						<FormCheck name="archived" text={translate("goals.archived")} disabled={false} />
					</>
				)}
			</FormGroup>
			{approvalFlag && userType === "Administrator" && (
				<Block marginTop="-10px">
					<FormGroup
						title={
							<Block marginTop="10px">
								<FormCheck name="requireApprovals" text={translate("approvals.requireApprovals")} />
							</Block>
						}
					>
						<Block marginTop="-5px">
							<FormCheck
								name="approvalOptions.updates"
								text={translate("approvals.updateApproval")}
								disabled={!requireApprovals}
							/>
							<FormCheck
								name="approvalOptions.edits"
								text={translate("approvals.editApproval")}
								disabled={!requireApprovals}
							/>
							<FormCheck
								name="approvalOptions.adds"
								text={translate("approvals.addApproval")}
								disabled={!requireApprovals}
							/>
							<FormCheck
								name="approvalOptions.deletes"
								text={translate("approvals.deleteApproval")}
								disabled={!requireApprovals}
							/>
						</Block>
					</FormGroup>
				</Block>
			)}
			{approvalFlag && userType === "Administrator" && (
				<FormGroup title={translate("approvals.approvers")}>
					<p>{translate("approvals.approverNote")}</p>
					<FormSelectScorecardAdmin
						disabled={!requireApprovals}
						label={`${translate("scorecard")} ${translate("global.owner")}`}
						data-testid="ownerId"
						requiredFlag
						scorecardId={scorecardId}
						name="ownerId"
					/>
					<FormSelectEditor
						label={`${translate("scorecard")} ${translate("global.collaborators")}`}
						disabled={!requireApprovals}
						data-testid="collaborators"
						includeNotDefinedFlag
						scorecardId={scorecardId}
						multipleFlag
						name="collaborators"
					/>
				</FormGroup>
			)}
			{approvalFlag && userType === "Administrator" && (
				<FormGroup title={translate("approvals.approvalOptions")}>
					<FormCheck
						name="approvalOptions.allowCollaboratorApprovals"
						text={translate("approvals.allowCollaboratorApprovals")}
						disabled={!requireApprovals}
					/>
					<FormCheck
						name="approvalOptions.allowCollaboratorChanges"
						text={translate("approvals.allowCollaboratorChanges")}
						disabled={!requireApprovals}
					/>
				</FormGroup>
			)}
		</>
	);
};
export default OptionsTab;
