/* eslint-disable complexity */
import { isEqual } from "lodash";
import isEmpty from "lodash/isEmpty";

import { findUpdates } from "@clearpoint/utils";

import slimPayload from "./slimPayload";
import getLinkedUpdatesList from "./utils/getLinkedUpdatesList";

let generateOnSubmitCallback =
	({
		beforeSubmit,
		clear,
		copyFlag,
		defaultValue,
		duplicateRouteFlag,
		formatDate,
		getPromise,
		object,
		objectId,
		objectIdList,
		onSubmit,
		parent,
		parentId,
		queryStringOnSubmit,
		scorecardId,
		sessionPeriodId,
		set,
		skipClearFlag,
		submitUnchangedFlag,
		updateFlag,
	}) =>
	async (submitValue, contextValue, skipCloseFlag) => {
		if (beforeSubmit) submitValue = (await beforeSubmit(submitValue, contextValue)) || submitValue;
		let response;
		if (Object.keys(contextValue.formValue).length > 0) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			if ((updateFlag && !copyFlag && objectId) || objectIdList) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				objectIdList = objectIdList || [objectId];
				let submitValueCopy = { ...submitValue };
				let defaultValueCopy = { ...defaultValue };
				delete submitValueCopy.updates;
				delete defaultValueCopy.updates;
				let data = {};
				let { measureData } = submitValueCopy;
				if (!measureData) delete submitValueCopy.measureData;
				let measureEditsPayload;
				if (measureData && object !== "measure") {
					let shadowMeasureId = await getPromise({ object, objectId }).then((x) => x.shadowMeasureId);
					measureEditsPayload = {
						object: "measure",
						objectId: shadowMeasureId,
						fields: { measureData },
					};
					delete submitValueCopy.measureData;
					delete defaultValueCopy.measureData;
				}
				// remove unchanged links
				let currentLinks = slimPayload(submitValueCopy)?.links;
				let defaultLinks = slimPayload(defaultValue)?.links;
				if (isEqual(currentLinks, defaultLinks)) delete submitValueCopy.links;

				let edits =
					Object.keys(submitValueCopy).length > 0
						? objectIdList.map((objectId) => ({
								fields: slimPayload(submitValueCopy),
								object,
								objectId,
						  }))
						: [];

				if (measureEditsPayload) edits.push(measureEditsPayload);

				let periodId = contextValue.formValue.updates?.periodId || sessionPeriodId;

				let updateFieldList = submitUnchangedFlag
					? submitValue.updates
					: findUpdates({
							original: defaultValue?.updates || [],
							updated: submitValue?.updates || [],
					  });
				let updates =
					submitValue.updates && !isEmpty(updateFieldList)
						? objectIdList.map((objectId) => ({
								fields: slimPayload(updateFieldList),
								object,
								objectId,
								periodId,
						  }))
						: [];

				if (submitValue?.links) {
					updates = [...updates, ...getLinkedUpdatesList({ fieldKey: "statusId", submitValue, contextValue })];
					edits = [
						...edits,
						...getLinkedUpdatesList({ fieldKey: "completed", submitValue, contextValue }),
						...getLinkedUpdatesList({ fieldKey: "completedDate", submitValue, contextValue }),
					];
					for (const edit of edits) {
						const fields = edit.fields;
						if (!fields) continue;
						const { completedDate, completed } = fields;
						if (completedDate && completed === undefined) {
							delete edit.fields.completedDate;
						} else if (completed === false) {
							edit.fields.completedDate = "";
						} else if (completed === true && !completedDate) {
							edit.fields.completedDate = formatDate(new Date().toISOString(), "apiDate");
						}
					}
				}

				for (const edit of edits) {
					const fields = edit.fields;
					if (!fields) continue;
					const { completedDate, completed } = fields;
					if (completedDate && completed === undefined) {
						delete edit.fields.completedDate;
					} else if (completed === false) {
						edit.fields.completedDate = "";
					} else if (completed === true && !completedDate) {
						edit.fields.completedDate = formatDate(new Date().toISOString(), "apiDate");
					}
				}

				data.edits = edits?.filter((x) => !isEmpty(x.fields));
				data.updates = updates?.filter((x) => !isEmpty(x.fields));
				if (edits.length > 0 || updates.length > 0) {
					response = await set({
						object: "update",
						data,
					});
				}
				for (const objectId of objectIdList) clear({ object, objectId });
				//if (measureData) {
				//	clear({ object: "customField" });
				//	for (let object of objectList) clear({ object });
				//}
			} else if (!isEmpty(submitValue) || duplicateRouteFlag) {
				if (duplicateRouteFlag) {
					let { endDate, initiativeId, name, periodId, scorecardId, startDate } =
						contextValue.formDefaultValue || {};
					submitValue = { endDate, initiativeId, name, periodId, scorecardId, startDate, ...submitValue };
				}

				if (!objectId && !submitValue?.completed) {
					submitValue.completedDate = undefined;
				}

				response = await set({
					data: submitValue,
					object,
					objectId: copyFlag ? undefined : objectId,
					parent,
					parentId,
					queryString: queryStringOnSubmit ?? (duplicateRouteFlag ? `?duplicate=${objectId}` : undefined),
					scorecardId,
					skipClearFlag,
				});
			}
		}
		if (onSubmit) onSubmit(submitValue, response, contextValue, skipCloseFlag);
		return response;
	};

export default generateOnSubmitCallback;
