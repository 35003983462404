import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import "./addIconsToLibrary.Brands";
import "./addIconsToLibrary.Light";
import "./addIconsToLibrary.Regular";
import "./addIconsToLibrary.Solid";

import { iconSizes as sizes } from "../values";
import iconColorLookup from "./iconColorLookup";
import iconDictionary from "./iconDictionary";
import StyledIcon from "./StyledIcon";

let propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	cursor: PropTypes.string,
	"data-testid": PropTypes.string,
	disabled: PropTypes.bool,
	flip: PropTypes.oneOf(["both", "horizontal", "vertical"]),
	fontSize: PropTypes.string,
	grow: PropTypes.string,
	hover: PropTypes.string,
	marginLeft: PropTypes.string,
	marginRight: PropTypes.string,
	name: PropTypes.string,
	noFixedWidthFlag: PropTypes.bool,
	noTitleFlag: PropTypes.bool,
	onClick: PropTypes.func,
	pointerFlag: PropTypes.bool,
	rotation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	size: PropTypes.string,
	spinFlag: PropTypes.bool,
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	transform: PropTypes.string,
};

let defaultProps = {
	name: "",
};

let Icon = ({
	className,
	color,
	"data-testid": dataTestId,
	disabled,
	flip,
	fontSize,
	grow,
	hoverBrightness,
	inverse,
	marginLeft,
	marginRight,
	name,
	noFixedWidthFlag,
	noTitleFlag,
	onClick,
	onMouseOut,
	onMouseOver,
	pointerFlag = false,
	rotation,
	size,
	iconTitle,
	spinFlag,
	style,
	transform,
}) => {
	let translate = useTranslate();
	if (name === "index") return;
	return name ? (
		<StyledIcon
			className={className}
			$color={color || iconColorLookup[name]}
			data-testid={dataTestId}
			disabled={disabled}
			flip={flip}
			$fontSize={fontSize}
			$hoverBrightness={hoverBrightness}
			inverse={inverse}
			icon={iconDictionary[name] || name}
			$marginLeft={marginLeft}
			$marginRight={marginRight}
			$noFixedWidthFlag={noFixedWidthFlag}
			$pointerFlag={pointerFlag}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			$rotation={rotation}
			size={sizes[size] || size}
			spin={spinFlag}
			style={style}
			title={noTitleFlag ? undefined : iconTitle ? translate?.(iconTitle) : translate?.(`icon.${name}`)}
			transform={(transform || "") + (grow ? "grow-" + grow : "")}
		/>
	) : null;
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;

export { default as iconDictionary } from "./iconDictionary";
