import { useCallback } from "react";
import PropTypes from "prop-types";

import { useTranslate } from "@clearpoint/translate";

import SelectObject from "./Select.Object";
import getCreateLabel from "./Select.ReferenceSeries.getCreateLabel";

let propTypes = {
	parent: PropTypes.string,
	parentId: PropTypes.number,
	object: PropTypes.string,
};
let SelectReferenceSeries = ({ parent, parentId, object, ...props }) => {
	let translate = useTranslate();
	let queryString;
	let parentObject;
	if (!parentId) {
		queryString = `?object=${parent}`;
		parentObject = parent;
		parent = undefined;
	}
	let createLabel = useCallback(
		(x) => {
			let createLabelFunction = getCreateLabel({ parent, parentObject, translate });
			return createLabelFunction(x);
		},
		[parent, parentObject, translate]
	);
	return (
		<SelectObject
			createLabel={createLabel}
			object="referenceSeries"
			parent={parent}
			parentId={parentId}
			queryString={queryString}
			placeholder={translate("global.selectReferenceField")}
			{...props}
		/>
	);
};
SelectReferenceSeries.propTypes = propTypes;

export default SelectReferenceSeries;
