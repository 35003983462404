import { useCallback, useMemo, useRef } from "react";
import classNames from "classnames";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import Form from "@clearpoint/old-theme/Form/Form";

import { useEvaluationEnabledLookup } from "@clearpoint/hooks";
import { useTranslate } from "@clearpoint/translate";
import { emptyArray, emptyObject, splitEditAndUpdateFieldData } from "@clearpoint/utils";
import { toast } from "@clearpoint/services/toastService/index";

import { useDetailPageData } from "../DataProvider";
import useClearAllOnSubmit from "./useClearAllOnSubmit";
import useSubmitMilestoneGanttData from "./useSubmitMilestoneGanttData";
import { useLocalState } from "@clearpoint/providers/LocalStateProvider";

let propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	periodId: PropTypes.number,
};

let DetailForm = ({ children, className, periodId }) => {
	let translate = useTranslate();
	let { customFieldData, element, elementStatus, ganttData, linkList, object, objectId } = useDetailPageData();
	let { setLocalState } = useLocalState();
	let clearAll = useClearAllOnSubmit();
	let evaluationEnabledFlag = useEvaluationEnabledLookup()[object];
	let submitMilestoneGanttData = useSubmitMilestoneGanttData();
	let ganttFlagRef = useRef(false);

	let defaultValue = useMemo(() => {
		if (element && customFieldData && elementStatus) {
			let { editFieldData: editCustomFieldData, updateFieldData: updateCustomFieldData } =
				splitEditAndUpdateFieldData(customFieldData);

			let updates = { ...elementStatus, ...updateCustomFieldData };
			if (periodId) updates.periodId = periodId;

			return {
				...element,
				...editCustomFieldData,
				updates,
				links: linkList,
				ganttData,
				measureData: evaluationEnabledFlag ? emptyArray : undefined,
				reportUpdate: "",
			};
		}
		return emptyObject;
	}, [element, customFieldData, elementStatus, linkList, ganttData, evaluationEnabledFlag, periodId]);

	let beforeSubmit = useCallback(
		(submitValue, contextValue) => {
			submitValue = cloneDeep(submitValue);
			delete submitValue.updatedBy;

			if (submitValue.reportUpdate && !isEmpty(submitValue.reportUpdate)) {
				let { field, value } = submitValue.reportUpdate;
				submitValue[field] = submitValue[field] || {};
				submitValue[field].value = value;
			}
			delete submitValue.reportUpdate;

			for (let key of Object.keys(submitValue)) {
				if (key.startsWith("elementList")) {
					delete submitValue[key];
				}
			}

			if (submitValue.ganttData) {
				ganttFlagRef.current = true;
				let { formDefaultValue } = contextValue;
				submitMilestoneGanttData({ ganttData: submitValue.ganttData, formDefaultValue });
				delete submitValue.ganttData;
			}

			setLocalState({ lockedPodList: emptyArray });
			return submitValue;
		},
		[setLocalState, submitMilestoneGanttData]
	);

	let onSubmit = useCallback(() => {
		clearAll();
		if (ganttFlagRef.current === true) {
			toast.success(translate("toaster.messages.gantt.saved"));
			ganttFlagRef.current = false;
		}
	}, [clearAll, translate]);

	return (
		<Form
			className={classNames(className, "detail-page-form")}
			object={object}
			objectId={objectId}
			periodId={periodId}
			beforeSubmit={beforeSubmit}
			defaultValue={defaultValue}
			onSubmit={onSubmit}
			updateFlag
		>
			{children}
		</Form>
	);
};

DetailForm.propTypes = propTypes;
export default DetailForm;
